import React, { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ContentItem, IDirectoryContent} from '../types'
import { Button, ButtonGroup, Col, Container, Dropdown, Modal, ProgressBar, Row } from 'react-bootstrap'
import { useLoaderData, useLocation } from 'react-router-dom'
import { myfileSize, myfileSize2 } from '../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload,  faXmark, faBan} from '@fortawesome/free-solid-svg-icons';
import { Mutex } from 'async-mutex'
import { useQueryClient } from 'react-query'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

export interface FileItemProps {
  file: ContentItem,
  basePath: string,
  cloudStorageId: string,
  setFinished:  (success: boolean) => void
}

interface FileItemRef {
  startDownload: (directoryHandle: FileSystemDirectoryHandle | undefined, signal: AbortSignal ) => void
}

const FileItem = forwardRef( ({file, setFinished, basePath, cloudStorageId} : FileItemProps, ref: React.ForwardedRef<FileItemRef> ) => {
  const folderContent = useLoaderData() as IDirectoryContent;
  const [progress, setProgress] = React.useState(0)  ;
  const [error, setError] = React.useState("") ;
  const pseudoSize =  !file.isFile || file.size=== 0 ? 1 : file.size;
  const tokenProvider = folderContent.tokenProvider;
  const appInsights = useAppInsightsContext();

  const downloadBlobBrowser = (fileName: string, blob: Blob) => {
    const anchor = window.document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
  } 


  const fetchData = async (directoryHandle: FileSystemDirectoryHandle | undefined, signal: AbortSignal ) => {
    const path = file.path.split('/').slice(0,-1).join('/');
    if (file.isFile) {

      const blob = await tokenProvider.download(path,file,{
        onprogress: (p) => {
          setProgress(p.loadedBytes);
        },
        abortSignal: signal
      });
      if (blob) {
        if (directoryHandle===undefined) {
          downloadBlobBrowser(file.name, blob);
          appInsights.trackEvent({name: "DownloadPopup1", properties: { cloudStorageId: cloudStorageId,  file: file.path, size: file.size.toString() }});


        } else {
          const subFolderPart = file.path.replace(basePath, "").split('/').slice(0,-1);
          for ( const subFolder of subFolderPart) {
            if (subFolder)
              directoryHandle = await directoryHandle.getDirectoryHandle(subFolder.replace(/[/\\:*?<>|]/g,'_'), {create: true});
          }
          const fileHandle = await directoryHandle.getFileHandle(file.name.replace(/[/\\:*?<>|]/g,'_'), {create: true});
          const writable = await fileHandle.createWritable();
          await writable.write(blob);
          await writable.close();
          appInsights.trackEvent({name: "DownloadPopup2", properties: { cloudStorageId: cloudStorageId,  file: file.path, size: file.size.toString() }});
        }
      }

    }  else {
      const subFolderPart = file.path.replace(basePath, "").split('/').slice(0);
      if (directoryHandle===undefined ) {
        console.log("cannot create folder " + subFolderPart.join('/') + " in default donwload directory");
      } else {
        for ( const subFolder of subFolderPart) {
          if (subFolder)
            directoryHandle = await directoryHandle.getDirectoryHandle(subFolder.replace(/[/\\:*?<>|]/g,'_'), {create: true});
        }
      }
      setProgress(1);

    }

  }

  useImperativeHandle(ref, () => ({
    startDownload : (directoryHandle: FileSystemDirectoryHandle | undefined, signal: AbortSignal) => {
      fetchData(directoryHandle,signal)
        .then(() => {
          setFinished(true);
        })
        .catch((error:any) => {
          console.log(error); 
          setError(error?.toString());
          setFinished(false);
        });
    }
  }));

  return (
      <Row key={file.path}>
        <Col xs={6} className='text-truncate' title={file.name}>{file.path.replace(basePath, "")}</Col>
        <Col xs={2} className='text-right' >{file.isFile ? myfileSize(file.size) : "" }</Col>
        <Col><ProgressBar now={  progress *100 / pseudoSize } /><span>{error}</span></Col>
      </Row>
  );

});

export interface FolderItemProps {
  folder: ContentItem,
  basePath: string,  
  cloudStorageId: string,
  setFinished:  (folder:ContentItem, elements: ContentItem[]) => void 
}

interface FolderItemRef {
}

const FolderItem = forwardRef( ({folder, setFinished, basePath, cloudStorageId} : FolderItemProps, ref: React.ForwardedRef<FolderItemRef> ) => {
  const folderContent = useLoaderData() as IDirectoryContent;
  const [error, setError] = React.useState("") ;
  const tokenProvider = folderContent.tokenProvider;
  const queryClient = useQueryClient();

  useEffect(() => {
    const getFromCacheOrFetch = async () => {
      const queryKey = ["FileListDownload", cloudStorageId, folder.path];
      const staleTime = 1 * 60 * 1000; // 1 minute
      const content = await (queryClient.getQueryData<IDirectoryContent>(queryKey) ?? 
        queryClient.fetchQuery(queryKey,  () => {
            return tokenProvider.get(folder.path)
          }
          ,{staleTime:staleTime}));
      return content;
    }

    if (folder ) {
      getFromCacheOrFetch()
        .then((content) => {
          setFinished(folder, content.elements);
        })
        .catch((error:any) => {
          console.log(error); 
          setError(error?.toString());
        });
    }
  }, [folder, setFinished, tokenProvider, queryClient, cloudStorageId]);

  return (
      <Row key={folder.name}>
        <Col xs={6} className='text-truncate' title={folder.name}>{folder.path.replace(basePath,"")}</Col>
        <Col xs={2} className='text-right' ></Col>
        <Col><ProgressBar now={  0 } /><span>{error}</span></Col>
      </Row>
  );

});


export interface FileListDownloadProps {
  files: ContentItem[],
  basePath: string;
  show: boolean,
  cloudStorageId: string,
  close:  () => void;
}

export const FileListDownload = 
  (props: FileListDownloadProps) => {
    const [inProgress, setInProgress] = useState<boolean>(false);    
    const [allDownloaded, setAllDownloaded] = useState<boolean>(false);
    const [downloadedStatus, setDownloadedStatus] = useState<boolean>(true);  
    const location = useLocation();
    const [files, setFiles] = React.useState<ContentItem[]>([])
    const [filesToExpand, setFilesToExpand] = React.useState<ContentItem[]>([])
    const [filesRefs, setFilesRefs] = React.useState<React.RefObject<FileItemRef | FolderItemRef>[]>([])
    const [abortController, setAbortController] = useState< AbortController | undefined>(undefined);    
    const [hasFolder, setHasFolder] = useState<boolean>(false);
    const [listFileComplete, setListFileComplete] = useState<boolean>(false);

    useEffect(() => {
      if (props.show) {
        setFilesRefs(props.files.map(() => createRef<FileItemRef| FolderItemRef>()));
        const hasFolder = props.files.some((v)=>!v.isFile);
        setHasFolder(hasFolder);
        setListFileComplete( !hasFolder);
        setFilesToExpand(props.files.filter((v)=>!v.isFile));
        setFiles(props.files);
      }
    }, [props.show, props.files]);

    function isFileItemRef(e: FileItemRef | FolderItemRef): e is FileItemRef {
      return (e as FileItemRef).startDownload !== undefined;
    }

    const handleClose = () => {
      setInProgress(false);    
      setAllDownloaded(false);
      setDownloadedStatus(true);
      setAbortController(undefined);
      props.close();
    }
  
    const handleCancel = () => {
      if (abortController) {
        abortController.abort();
        setInProgress(false);    
        setAllDownloaded(true);
        setDownloadedStatus(false);
      } else {
        window.location.reload();
      }
    }; 

    const downloadStart = (directoryHandle: FileSystemDirectoryHandle | undefined ) => {
      const myAbortController = new AbortController();
      setAbortController(myAbortController);
      setDownloadedStatus(true);
      filesRefs.forEach(ref => {
          if (ref.current && isFileItemRef(ref.current))
            ref.current.startDownload(directoryHandle, myAbortController.signal);
        });
    }

    const downloadDefaultFolder = async (event: any) => {
      event?.persist();
      event?.preventDefault();
      setInProgress(true);
      downloadStart(undefined);
    };  
    const downloadChooseFolder = async (event: any) => {
      event?.persist();
      event?.preventDefault();
      // id : to retain where we donwload the previous time
      // rfc https://wicg.github.io/file-system-access/ : A valid path id is a string where each character is ASCII alphanumeric or "_" or "-". 
      const directoryHandle = await window.showDirectoryPicker( { id: location.pathname.toLocaleLowerCase().replace(/[^a-z0-9_-]/g,"").substring(0,30), mode: "readwrite" });
      if (directoryHandle !== undefined)
      {
        setInProgress(true);
        downloadStart(directoryHandle);

      } 
    };
    const download = async (event: any) => {
      if ( hasFolder && window.showDirectoryPicker!== undefined)
        downloadChooseFolder(event);
      else
        downloadDefaultFolder(event);
    }

    
    let counter = files.length;
    const setFinished = (success: boolean) => {
      counter --;
      setDownloadedStatus( (old) => old && success);
      if (counter<=0){
        setInProgress(false);
        setAllDownloaded(true);
        setAbortController(undefined);
      }
    }
    const mutex = new Mutex();
    const setFolderFinished = (folder:ContentItem, elements: ContentItem[]) => {
      mutex.runExclusive(() => {
        const index = filesToExpand.findIndex((v)=>v.path === folder.path);
        if (index>=0)
        {
          const newFilesToExpand = filesToExpand.slice();
          newFilesToExpand.splice(index,1);
          newFilesToExpand.push(...elements.filter((v)=>!v.isFile));
          setFilesToExpand(newFilesToExpand); 
          const newFiles = files.slice();
          newFiles.push(...elements);
          const newFilesRefs = filesRefs.slice();
          newFilesRefs.push(...elements.map(() => createRef<FileItemRef| FolderItemRef>()));
          setFilesRefs(newFilesRefs);
          setFiles(newFiles);
          setListFileComplete( newFilesToExpand.length === 0);
        }
      });
    }

    return (
      <Modal show={props.show} onHide={handleClose} size="lg"  backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Download files</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Container fluid className='mt-4'>
            {files.map((file: ContentItem, index:number ) => {
                return (
                  <FileItem ref={filesRefs[index] as React.RefObject<FileItemRef>} key={file.path} file={file} setFinished={setFinished} basePath={props.basePath} cloudStorageId={props.cloudStorageId}/>
                );
              })
            }
            {
              filesToExpand.map((file: ContentItem, index:number ) => {
                return (
                  <FolderItem ref={filesRefs[files.length+index] as React.RefObject<FolderItemRef>} key={file.path} folder={file} setFinished={setFolderFinished} basePath={props.basePath} cloudStorageId={props.cloudStorageId}/>
                );
              })
            }
            {
              files.length === 0 &&
              <span>no file to download</span>
            }
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {
            allDownloaded===false &&
            <span className='me-5'>To download: {myfileSize2(files.map((v)=>v.isFile?v.size:0).reduce((a,b)=>a+b,0))}
            {!listFileComplete && " (get the list of files in progress)"}
            </span>
          }
          {
            allDownloaded===true && downloadedStatus===true &&
            <span className='me-5 text-success'>All files downloaded</span>
          }
          {
            allDownloaded===true && downloadedStatus===false &&
            <span className='me-5 text-danger'>At least one error</span>
          }
          <Button variant="secondary" onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} /> Close
          </Button>
          <Button variant="secondary" disabled={!inProgress} onClick={handleCancel}>
            <FontAwesomeIcon icon={faBan} /> Cancel
          </Button>
          <Dropdown as={ButtonGroup}>
            <Button variant="primary" disabled={inProgress || files.length===0 || !listFileComplete} onClick={download}>
              <FontAwesomeIcon icon={faDownload} /> 
              { !listFileComplete && "Calc list files"}
              { listFileComplete && "Download" }
            </Button>
            <Dropdown.Toggle split variant="primary" />
            <Dropdown.Menu>
              <Dropdown.Item onClick={downloadDefaultFolder} active={!hasFolder || window.showDirectoryPicker === undefined} data-toggle="tooltip" title="download in the default download directory">In default download folder</Dropdown.Item>
              <span onClick={downloadChooseFolder} onKeyUp={downloadChooseFolder} data-toggle="tooltip" title={window.showDirectoryPicker === undefined ? "not available on this browser, prefer a recent version of Chrome or Edge": "the remote tree will be reproduced in the local folder"}>
                <Dropdown.Item disabled={ window.showDirectoryPicker === undefined } active={hasFolder && window.showDirectoryPicker!== undefined } >
                    Choose folder and download
                </Dropdown.Item>
              </span>            
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Footer>          
      </Modal>      

      );
  }
  FileListDownload.displayName = 'FileListDownload'