import React, { createRef, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { IDirectoryContent} from '../types'
import { Button, Col, Container, Modal, ProgressBar, Row } from 'react-bootstrap'
import { useLoaderData, useLocation } from 'react-router-dom'
import { myfileSize } from '../utils'
import { DropZone } from './DropZone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faFileImport, faUpload, faXmark } from '@fortawesome/free-solid-svg-icons'
import { setVirtualParent } from '@fluentui/dom-utilities';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'


interface FileOrDirectory {
  file?: File;
  directory?: FileSystemDirectoryHandle;
  relativePath: string; // include the name of the file or directory. Do not start with /
  relativeDirectory?: string; // if set, "" or end with "/"
}

interface FileItemProps {
  fileOrDirectory: FileOrDirectory,
  basePath: string,
  setFinished:  (success: boolean) => void;
  cloudStorageId: string;
}

interface FileItemRef {
  startUpload: (signal?: AbortSignal ) => void
}

const FileItem =forwardRef( ( {fileOrDirectory, setFinished, cloudStorageId, basePath} : FileItemProps, ref: React.ForwardedRef<FileItemRef>  ) => {
  const folderContent = useLoaderData() as IDirectoryContent;
  const [progress, setProgress] = React.useState(0)  
  const [error, setError] = React.useState("")  

  const tokenProvider = folderContent.tokenProvider;
  const appInsights = useAppInsightsContext();
  
  const pseudoSize = fileOrDirectory.file ? ( fileOrDirectory.file.size === 0 ? 1 : fileOrDirectory.file.size) : 1;

  useImperativeHandle(ref, () => ({
    startUpload : (signal?: AbortSignal) => {
      const uploadFileOrCreateFolder = async () => {
        let fullDir = basePath;
        if (fileOrDirectory.relativeDirectory)
          fullDir += (basePath.endsWith("/")? "": "/") + fileOrDirectory.relativeDirectory;
        if ( fullDir.endsWith("/"))
          fullDir = fullDir.substring(0, fullDir.length-1);
        if (fullDir.startsWith("/"))
          fullDir = fullDir.substring(1);
        if (fileOrDirectory.file) {
          await tokenProvider.upload(fullDir,fileOrDirectory.file, { 
            onprogress: (p) => {
              setProgress(p.loadedBytes);
            },
            abortSignal: signal 
          });
          appInsights.trackEvent({name: "UploadPopup", properties: { cloudStorageId: cloudStorageId, folder: fullDir,  file: fileOrDirectory.file.name, size: pseudoSize.toString() }});

        } else if ( fileOrDirectory.directory) {
          await tokenProvider.createFolder(fullDir, fileOrDirectory.directory.name, { abortSignal: signal });
          appInsights.trackEvent({name: "CreateFolderUploadPopup", properties: {cloudStorageId: cloudStorageId,  folder: fullDir, newFolder:fileOrDirectory.directory.name }});

          setProgress(1);
        }
        setFinished(true);
      }
      uploadFileOrCreateFolder().catch((error:any) => {
        console.log(error); 
        setError(error?.toString());
        setFinished(false);
      });
    }
  }));

  return <>
      <Row key={fileOrDirectory.relativePath}>
        <Col xs={6} className='text-truncate'>{fileOrDirectory.relativePath}</Col>
        <Col xs={2} className='text-right'>{myfileSize(pseudoSize) }</Col>
        <Col><ProgressBar now={  progress *100 / pseudoSize } /><span>{error}</span></Col>
      </Row>
  </>;

});

export interface FileListUploadProps {
  show: boolean,
  cloudStorageId: string,
  basePath: string,
  close:  () => void;
}

export const FileListUpload = 
  (props: FileListUploadProps) => {
    const folderContent = useLoaderData() as IDirectoryContent;    
    const inputFileRef = useRef<HTMLInputElement>(null);    
    const location = useLocation();
    const [files, setFiles] = useState<FileOrDirectory[]>([])
    const [filesRefs, setFilesRefs] = React.useState<React.RefObject<FileItemRef>[]>([])
    const [folders, setFolders] = useState<FileOrDirectory[]>([])
    const [foldersRefs, setFoldersRefs] = React.useState<React.RefObject<FileItemRef>[]>([])        
    const [nbFilesToUpload, setNbFilesToUpload] = useState<number>(0);
    const [isDropActive, setIsDropActive] = useState(false);
    const [abortController, setAbortController] = useState< AbortController | undefined>(undefined); 
    const [allDownloaded, setAllDownloaded] = useState<boolean>(false);
    const [downloadedStatus, setDownloadedStatus] = useState<boolean>(true);  

    const onUploadDragStateChange = React.useCallback((dragActive: boolean) => {
      setIsDropActive(dragActive)
    }, [])
  
    // Create handler for dropzone's onFilesDrop:
    const onUploadFilesDrop = React.useCallback((newFiles: File[]) => {
      setFilesRefs(newFiles.map(() => createRef<FileItemRef>()));
      setFiles(newFiles.map((file) => ({ file, relativePath: file.name})));
      setFoldersRefs([]);
      setFolders([]);

    }, [])

    const handleCloseUpload = () => {
      setIsDropActive(false);
      setFilesRefs([]);
      setFiles([]);
      setFoldersRefs([]);
      setFolders([]);
      setNbFilesToUpload(0);  
      setAbortController(undefined);
      setAllDownloaded(false);
      setDownloadedStatus(true);
      props.close();
    }

    const chooseFiles = (event: any) => {
      event?.persist();
      event?.preventDefault();
      Promise.resolve().then(() => {
        const target = event?.target as HTMLElement | undefined;
        if (inputFileRef.current) {
          if (target) {
            setVirtualParent(inputFileRef.current, target);
          }
          inputFileRef.current.click();
          if (target) {
            setVirtualParent(inputFileRef.current, null);
          }
        }
      });
    }
    const onChooseFiles = async (event: any) => {
        event?.persist();
        event?.preventDefault();
        const newFiles: File[]= Array.from(event.target.files);
        setFilesRefs(newFiles.map(() => createRef<FileItemRef>()));
        setFiles(newFiles.map((file) => ({ file, relativePath: file.name})));
        setFoldersRefs([]);
        setFolders([]);
  
    };  

    const listAllFilesAndDirs = async (dirHandle: FileSystemDirectoryHandle, baseDir: string ) => {
      const files: Array<FileOrDirectory> = [];
      for await (const [name, handle] of dirHandle) {
        if (handle.kind === 'directory') {
          const subFolder = { 
            directory: handle, 
            relativePath: baseDir + name, 
            relativeDirectory: baseDir
          };
          files.push(subFolder);
          files.push(...await listAllFilesAndDirs(handle, baseDir + name + "/"));
        } else {
          const file = { 
            file: await handle.getFile(), 
            relativePath: baseDir + name,
            relativeDirectory: baseDir 
          };
          files.push(file);
        }
      }
      return files;
    } 

    const chooseFolder = async (event: any) => {
      event?.persist();
      event?.preventDefault();
      // id : to retain where we donwload the previous time
      // rfc https://wicg.github.io/file-system-access/ : A valid path id is a string where each character is ASCII alphanumeric or "_" or "-". 
      const directoryHandle = await window.showDirectoryPicker( { id: location.pathname.toLocaleLowerCase().replace(/[^a-z0-9_-]/g,"").substring(0,30), mode: "read" });
      if (directoryHandle !== undefined)
      {
        const newItems = await listAllFilesAndDirs(directoryHandle, "");
        const files = newItems.filter((v)=>v.file);
        const folders = newItems.filter((v)=>v.directory); 
        setFilesRefs(files.map(() => createRef<FileItemRef>()));
        setFiles(files);
        setFoldersRefs(folders.map(() => createRef<FileItemRef>()));
        setFolders(folders);
      }       
    }

    const upload = async (event: any) => {
      event?.persist();
      event?.preventDefault();    
      const myAbortController = new AbortController();
      setAbortController(myAbortController);      
      // first create folders if any (and in the order)
      if (folders.length > 0) {
        setNbFilesToUpload(foldersRefs.length);
        foldersRefs[0].current?.startUpload( myAbortController.signal);
      } else {
        // second create files
        setNbFilesToUpload(filesRefs.length);
        filesRefs.forEach(ref => {
          ref.current?.startUpload( myAbortController.signal);
        });      
      }
    }

    const handleCancelUpload = () => {
      if (abortController) {
        abortController.abort();
        setNbFilesToUpload(0);   
        setAbortController(undefined);   
        setAllDownloaded(true);
        setDownloadedStatus(false);             
      } else {
        window.location.reload();
      }
    };
    const setFinishedFolder = () => {
      setNbFilesToUpload (old => {
        let newNb = old-1;
        if ( newNb === 0) {
          // now the files
          newNb = filesRefs.length;
          filesRefs.forEach(ref => {
            ref.current?.startUpload( abortController?.signal);
          });           
        } else {
          foldersRefs[foldersRefs.length-newNb].current?.startUpload( abortController?.signal);
        }
        return newNb;
      });
    }

    const setFinished = (success: boolean) => {
      setDownloadedStatus( (old) => old && success);
      setNbFilesToUpload (old => {
        if ( old === 1) {
          setAllDownloaded(true);          
          setAbortController(undefined);
        }
        return old-1;
      });
    }

    return (
      <Modal show={props.show} onHide={handleCloseUpload} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Upload files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset className={"upload_dropZone text-center mb-3 p-4" + (isDropActive?" highlight":"") }>            
            <DropZone  onDragStateChange={onUploadDragStateChange} onFilesDrop={onUploadFilesDrop}>
              <FontAwesomeIcon icon={faFileImport} size='3x' />     
                <p className="small my-2"><label htmlFor="dropzone">Drag &amp; Drop file(s) inside dashed region</label><br/><i>or</i></p>
                <input type="file" id="dropzone" ref={inputFileRef} multiple className='notvisible' onChange={onChooseFiles}   />
                <Button variant='secondary' onClick={(e)=>chooseFiles(e)} disabled={nbFilesToUpload>0 || folderContent?.baseFolder?.operationAllowed  !== "Write"} title="upload one or several files" >{nbFilesToUpload>0 ? "Uploading" : "Choose file(s)" }</Button>
                {
                  window.showDirectoryPicker!==undefined &&
                  <Button variant='secondary' className='mx-3' onClick={(e)=>chooseFolder(e)} disabled={nbFilesToUpload>0 || folderContent?.baseFolder?.operationAllowed  !== "Write"} title="upload a folder recursively in the cloud" >{nbFilesToUpload>0 ? "Uploading" : "Choose folder" }</Button>
                }

                <div className="upload_gallery d-flex flex-wrap justify-content-center gap-3 mb-0">      
                  <Container fluid className='mt-4'>
                    {folders.map((file:FileOrDirectory, index:number ) => {
                        return (
                          <FileItem ref={foldersRefs[index]} key={file.relativePath} fileOrDirectory={file} setFinished={setFinishedFolder} basePath={props.basePath} cloudStorageId={props.cloudStorageId} />
                        )
                      }
                    )}                    
                    {files.map((file:FileOrDirectory, index:number ) => {
                      return (
                        <FileItem ref={filesRefs[index]} key={file.relativePath} fileOrDirectory={file} setFinished={setFinished} basePath={props.basePath} cloudStorageId={props.cloudStorageId}/>
                      )
                    }
                    )}
                  </Container>
                </div>
            </DropZone>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          {
            allDownloaded===false &&
            <span className='me-5'>To upload: {myfileSize(files.map((v)=>v.file ? v.file.size : 0).reduce((a,b)=>a+b,0))}</span>
          }
          {
            allDownloaded===true && downloadedStatus===true &&
            <span className='me-5 text-success'>All files uploaded</span>
          }
          {
            allDownloaded===true && downloadedStatus===false &&
            <span className='me-5 text-danger'>At least one error</span>
          }          
          <Button variant="secondary" onClick={handleCloseUpload}>
            <FontAwesomeIcon icon={faXmark} /> Close
          </Button>
          <Button variant="secondary" disabled={nbFilesToUpload<=0} onClick={handleCancelUpload}>
            <FontAwesomeIcon icon={faBan} /> Cancel
          </Button>
          <Button variant="primary" disabled={nbFilesToUpload>0 || files.length===0} onClick={upload}>
            <FontAwesomeIcon icon={faUpload} /> Upload
          </Button>
        </Modal.Footer>
      </Modal>                
      );
  }
  FileListUpload.displayName = 'FileListUpload'